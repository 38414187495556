import React from "react"
import styled from "styled-components"
import Iframe from "react-iframe"
import { Link } from "gatsby"

import { motion } from "framer-motion"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Features from "../components/common/features"
import ProductSteps from "../components/sections/productsteps"
import Categories from "../components/sections/categories"

import ImageSlider from "../components/common/imageslider"
import Footer from "../components/sections/footer"

import AboutHeader from "../components/sections/about/aboutheader"

import TextColumn from "../components/common/textcolumn"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

const HomePage = () => (
  <Layout>
    <SEO title="Home Page" />
    <Navigation></Navigation>
    <HeaderColumn>
      <TextColumn></TextColumn>
      <HalfColumn><ImageSlider /></HalfColumn>
    </HeaderColumn>
    <SectionDivider>The dili story</SectionDivider>
    <AboutHeader></AboutHeader>
    <SectionDivider>Put your dreams on the fast track with dili</SectionDivider>
    <Features></Features>
    <SectionDivider>Come, be a better version of yourself in 4 simple steps</SectionDivider>
    <ProductSteps></ProductSteps>
    <SectionDivider>A myriad opportunities to build and enjoy a bigger life</SectionDivider>
    <Categories></Categories>
    <Footer></Footer>
  </Layout>
)

export default HomePage

export const MotionTest = styled(motion.button)`
    background: blue;
    border-radius: 30px;
    width: 150px;
    height: 150px;
    margin: 100px;
`

export const HeaderColumn = styled.div`
    display: flex;

    @media (max-width: ${props => props.theme.screen.md}) {
      flex-direction: column;
      height: auto;
    };

    @media (max-width: ${props => props.theme.screen.sm}) {
      flex-direction: column;
      height: auto;
    };

    @media (max-width: ${props => props.theme.screen.xs}) {
      flex-direction: column;
      height: auto;
    };
`